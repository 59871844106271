<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :scroll="{x: true}"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="slot_table_list"
            :is_page="false"
            ref="list"
            rowKey="id"
            :submit_preprocessing="submit_preprocessing"
            @expandedRowsChange="expandedRowsChange($event)"
            @list_after="list_after"
        >

			<template slot="right_btn">
                <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.export/orderCensus"></export-table>
            </template>

            <template slot="table_before">
                <div
                    style="text-align:center; padding-bottom:15px"
                >{{chart_name ? chart_name + "的" : '总'}}订单金额</div>
                <div id="container" style="height:550px; width:100%"></div>
            </template>

            <div v-for="(vo,index) in slot_table_list" :key="index" :slot="vo" slot-scope="data">
                <span v-if="vo == 'total'">{{data.text.total + '元 | ' + data.text.num + '件'}}</span>
                <a v-else-if="vo == 'operation'" @click="get_details(data.record)">查看图表</a>
                <span v-else>{{data.text.total + '元 | ' + data.text.num + '件'}}</span>
            </div>
        </TableList>
    </div>
</template>

<script>

import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import RightDrawer from "@/components/RightDrawer";
import LookImages from "@/components/LookImages";
import { orderReport, orderReportSub } from "@/api/order";
import { getGoodsTitle, } from "@/api/goods";

import { Chart } from '@antv/g2';



let columns = [
    {
        title: "名称",
		fixed: 'left',
        dataIndex: "name",
    },
    {
        title: "合计",
		fixed: 'left',
        dataIndex: "total",
        scopedSlots: {
            customRender: "total"
        }
    },
    // {
    //     title: "操作",
    //     scopedSlots: {
    //         customRender: "operation"
    //     }
    // },
];

const columns_details = [
    {
        title: '商品',
        dataIndex: 'goods_title'
    },
    {
        title: '价格',
        dataIndex: 'pay_price'
    },
    {
        title: '数量',
        dataIndex: 'num',
        scopedSlots: {
            customRender: "num"
        }
    },
    {
        title: '小计',
        dataIndex: 'sub_price'
    }
]

let times_list = [
    {
        key: 1,
        value: '24小时'
    },
    {
        key: 7,
        value: '最近7天'
    },
    {
        key: 30,
        value: '最近30天'
    }
]

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        RightDrawer,
        LookImages
    },
    data() {
        return {
            get_table_list: orderReport,
            submit_preprocessing: {
                array_to_string: ['department','role']
            },
            details: {
                visit_data: {

                },
            },
            chart: null,
            slot_table_list: ['operation', 'total'],
            chart_name: '',
            columns,
            columns_details,
            goods_list: [],
            drawer_visible: false,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "日期",
                        options: {},
						options: {
							initialValue: [this.$moment(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)), 'YYYY/MM/DD'), this.$moment(new Date(), 'YYYY/MM/DD')],
						},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],

            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});
		
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role") {
        //             item.list = res;
        //         }
        //     });
		// });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});
		

        let columns_list = [...this.columns]

        this.$method.get_goods_title().then(res => {
            this.goods_list = res.data.list;
            res.data.list.forEach(item => {
                this.slot_table_list.push(item.id)
                columns_list.push(
                    {
                        title: item.goods_title,
                        dataIndex: item.id,
                        width: 200,
                        scopedSlots: {
                            customRender: item.id
                        }
                    }
                )
            })
            columns_list.push({
                title: "操作",
                scopedSlots: {
                    customRender: "operation"
                },
                fixed: 'right',
                width: 100,
            })
            this.columns = columns_list;

        })
        this.$nextTick(res => {
            this.get_details();
        })
    },
    mounted() {
        const e = document.createEvent('Event')
        e.initEvent('resize', true, true)
        window.dispatchEvent(e)
    },
    methods: {
        created_chart() {
            const chart = new Chart({
                container: 'container',
                autoFit: true,
                height: 500,
                theme: 'default'
            });

            let data = [

            ];

            chart.data(data);
            this.chart = chart;
            chart.scale('total', {
                alias: '销售金额(元)',
            });

            chart.scale('num', {
                alias: '销售数量(件)',
            });

            chart.tooltip({
                showCrosshairs: true,
                shared: true,
            });

            chart.line()
                .position('xaxis*total')
				.color('#3182bd')
				.shape('smooth');

            chart
                .interval()
                .position('xaxis*num')
                .color('#1890ff')
                

            chart
                .point()
                .position('xaxis*total')
                .color('#1890ff')
                .shape('circle');

            chart.render();
        },
        get_details(item = {}) {
            if (!item.id) {
                item.id = '';
            } else {
                this.chart_name = item.name;

                let times = this.$refs.list.seo_data.times;

                if (!this.chart) {
                    this.created_chart();
                }

                let list = [];

                item.goods_data.forEach((vo, i) => {
                    list.push({
                        xaxis: this.goods_list[i].goods_title,
                        num: vo.num,
                        total: vo.total
                    })
                });


                this.chart.data(list)
                this.chart.render();

            }
			
            // censusChart({
            //     data: {
            //         id: item.id,
            //         times: times ? times : 1,
            // 		type: item.type,
            // 		...this.$refs.list.seo_data
            //     }
            // }).then(res => {

            //     if (!this.chart) {
            //         this.created_chart();
            //     }

            //     this.chart.data(res.data.list)
            //     this.chart.render();
            // })
        },
        get_list_key(list, key, index_arr = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == key) {
                    return list[i]
                }

                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let value = this.get_list_key(list[i].children, key);
                    if (value !== false) {
                        return value;
                    }
                }
            }

            return false;
        },
        expandedRowsChange(e) {
            e.forEach(key => {
                let list = JSON.parse(JSON.stringify(this.$refs.list.list));

                let item = this.get_list_key(list, key);

                if (!item.hasOwnProperty('is_req')) {
                    orderReportSub({
                        data: {
                            id: key,
                            ...this.$refs.list.seo_data
                        }
                    }).then(res => {

                        item.children = [
                            // ...res.data.user.map((vo) => {
                            //     vo.type = 1
                            //     return vo;
                            // }),
                            ...res.data.list.map((vo) => {
                                vo.children = [];
                                return vo;
                            })

                        ];
                        item.is_req = true;
                        this.$refs.list.set_data('list', list);
                    })
                }
            });
        },
        list_after(res) {
            
            let list = this.$refs.list.list;
            list = list.map((item,index) => {
				if(index == 0){
					this.get_details(item);
				}
                item.children = [];
                
                return item;
            });

        },
        show() {
            this.drawer_visible = true;
        },
        drawer_visible_close() {
            this.drawer_visible = false;
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.visit_img {
    width: 60px;
    height: 60px;
}
</style>